<template>
<div class="container-fluid">
  <breadcrumb>
      <img
        src="static/img/survey.svg"
        class="breadcrumb-image"
      />
      <breadcrumb-item>
        <router-link to="/designeffectiveness">Select Entity</router-link>
      </breadcrumb-item>
      <breadcrumb-item>
        <router-link to="../../..">Design Effectiveness</router-link>
      </breadcrumb-item>
       <breadcrumb-item>
        <router-link to="..">Design Effectiveness Detail</router-link>
      </breadcrumb-item>
      <breadcrumb-item active>Design Effectiveness Assessment</breadcrumb-item>
    </breadcrumb>
  <div class="row">
    <div class="col-md-12">
        <card v-if="loaded">
          <div class="text-right">
            <template v-if="model.isComplete === 'True'">
              <button
                type="button"
                @click="close"
                class="btn btn-fill btn-info btn-wd"
              >
                <span class="btn-label">
                  <i class="fa fa-arrow-circle-left"></i>
                </span>
                Back
              </button>
            </template>
            <template v-if="model.isComplete === 'False'">
              <button
                type="button"
                @click="close"
                class="btn btn-fill btn-danger btn-wd"
              >
                <span class="btn-label">
                  <i class="fa fa-times"></i>
                </span>
                Cancel
              </button>
              <button
                type="submit"
                @click="save"
                class="btn btn-fill btn-info btn-wd"
              >
                <span class="btn-label">
                  <i class="fa fa-save"></i>
                </span>
                Save
              </button>
            </template>
          </div>
          <survey :survey="survey"></survey>
        </card>
      </div>
    </div>
  </div>
</template>


<script>
import * as SurveyVue from "survey-vue";
import "bootstrap/dist/css/bootstrap.css";
import Swal from "sweetalert2";
import { Breadcrumb, BreadcrumbItem } from "src/components/index";

var Survey = SurveyVue.Survey;
Survey.cssType = "bootstrap";

SurveyVue.Serializer.addProperty("question", "tag:number");
SurveyVue.Serializer.addProperty("question", "weight:number");

export default {
    components: {
    Breadcrumb,
    BreadcrumbItem
  },
  data() {
    return {
      survey: new SurveyVue.Model(JSON.parse("{}")),
      model: {
        id: 0,
        answers: JSON.parse("{}"),
        percentageCompleted: 0,
        isComplete: "",
        score: 0,
        targetScore: 0,
      },
      loaded: false,
    };
  },

  created() {
    let self = this;
    self.get(self.$route.params.controlAssessmentSurveyId);
  },

  methods: {
    get(controlAssessmentSurveyId) {
      let self = this;
      self.$store.state.services.dEControlAssessmentSurveyService
        .get(controlAssessmentSurveyId)
        .then((r) => {
          self.model.id = r.data.id;
          self.survey = new SurveyVue.Model(JSON.parse(r.data.survey));
          self.model.answers = JSON.parse(r.data.answers);
          self.model.isComplete = r.data.isComplete;
          self.model.percentageCompleted = r.data.percentageCompleted;
          self.model.targetScore = r.data.targetScore;

          if (self.model.isComplete == "True") {
            //Read only survey
            self.survey.data = self.model.answers;
            self.survey.mode = "display";
            self.loaded = true;
          } else {
            if (r.data.answers !== "{}") {
              self.survey.data = self.model.answers;
            }

            self.survey.onComplete.add((result) => {
              Swal.fire({
                title: "Are you sure?",
                text: `You won't be able to revert this`,
                icon: "warning",
                showCancelButton: true,
                customClass: {
                  confirmButton: "btn btn-success btn-wd",
                  cancelButton: "btn btn-danger btn-wd",
                },
                confirmButtonText: "Complete",
                cancelButtonText: "Cancel & Save",
                buttonsStyling: false,
              }).then((response) => {
                //alert(JSON.stringify(response));
                if (response.isConfirmed) {
                  Swal.fire({
                    title: "Completed!",
                    text: "Assessment has been completed",
                    icon: "success",
                    buttonsStyling: false,
                    customClass: {
                      confirmButton: "btn btn-info btn-wd",
                    },
                  });
                  self.model.answers = result.data;
                  self.model.score = self.getAnswers().score;
                  self.model.percentageCompleted = 100;
                  self.model.isComplete = "True";
                  self.$store.state.services.dEControlAssessmentSurveyService.update(
                    self.model
                  );
                  self.$router.go(-1);
                } else {
                  /*Swal.fire({
                    title: "Saved",
                    text: `Assessment not complete`,
                    icon: "info",
                    buttonsStyling: false,
                    customClass: {
                      confirmButton: "btn btn-info btn-wd"
                    }
                  });*/
                  self.save();
                  self.survey.clear();
                  self.survey.render();
                }
              });
            });

            self.survey.onValueChanged.add((result) => {
              self.model.answers = result.data;
              self.model.percentageCompleted = self.getAnswers().percentageCompleted;
              self.model.isComplete = "False";
              //Add post to update DEControlAssessmentSurvey --- if we want to save real time???
            });

            //survey.onCurrentPageChanged.add(save);
            self.survey.showCompletedPage = false;
            self.survey.showPreviewBeforeComplete = "showAllQuestions";
            self.loaded = true;
          }
        });
      //.catch((err) => console.log(err));
    },

    getAnswers() {
      let self = this;
      var questions = self.survey.getQuizQuestions();
      var correct = 0;
      var score = 0;
      var percentageCompleted = 0;
      var totalWeight = 0;
      var notApplicableWeight = 0;
      var unAnswered = 0;

      questions.forEach((ques) => {
        if (ques.weight > 0) {
          totalWeight = totalWeight + ques.weight;
          if (ques.isAnswerCorrect()) {
            correct = correct + ques.weight;
          } else if (String(ques.value).toUpperCase().replace(/\s/g, '') === "N/A"
          || String(ques.value).toUpperCase().replace(/\s/g, '') === "NOTAPPLICABLE") {
            notApplicableWeight = notApplicableWeight + ques.weight;
          } else {
            if(ques.value == undefined){
              unAnswered++
            }
          }
        }
      });

      score = (Math.round(correct * 100) / (totalWeight - notApplicableWeight)).toFixed(0);
      if (score == "NaN") {
        score = "0";
      }
      percentageCompleted = (
        Math.round((questions.length - unAnswered) * 100) /
        (questions.length)
      ).toFixed(0);

      return {
        correct: correct,
        score: score,
        percentageCompleted: percentageCompleted,
      };
    },

    save() {
      let self = this;
      self.$store.state.services.dEControlAssessmentSurveyService.update(
        self.model
      );
      self.notifyVueSave("top", "right", "warning");
      self.close();
    },
    close() {
      let self = this;
      self.$router.push(
        "/designeffectiveness/" + self.$route.params.organisationId + "/deassessments/" + self.$route.params.id + "/detail"
      );
    },
    notifyVueSave(verticalAlign, horizontalAlign, messageType) {
      this.$notify({
        component: {
          template: `<span>Control Assessment saved</br> but not completed</span>`,
        },
        icon: "nc-icon nc-check-2",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: messageType,
      });
    },
  },
};
</script>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.sv_q_m_cell_text {
  font-weight: normal !important;
  font-size: 13px !important;
  background-color: white !important;
}
.sv_q_m_cell_selected {
  background-color: #d5f5df !important;
}

.sv_q_matrix td {
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border: 3px solid black;
  font-size: 13px;
  widows: 16.66%;
  background-color: #f0f0f0;
}
.sv_q_matrix {
  table-layout: fixed;
  width: 100%;
}
.sv_prev_btn,
.sv_preview_btn,
.sv_next_btn,
.sv_edit_btn,
.sv_start_btn,
.sv_prev_btn:hover,
.sv_next_btn:hover {
  border: 1px solid #087ea6 !important;
  color: #ffffff;
  background-color: #087ea6 !important;
  min-width: 140px;
  margin-left: 10px;
}

.sv_complete_btn {
  border: 1px solid #7ea700;
  color: #ffffff;
  background-color: #7ea700;
  min-width: 140px;
  margin-left: 10px;
}
.sv_complete_btn:hover {
  border: 1px solid #7ea700;
  color: #ffffff;
  background-color: #7ea700;
}

.alert.alert-danger,
.vue-notifyjs.alert.alert-danger {
  background-color: #f7f7f8;
  color: red;
}

h3 {
  font-size: 18px !important;
}
h5 {
  font-size: 14px !important;
}
.sv-q-col-1 {
  font-size: 14px !important;
}
.card-footer {
  text-align: right !important;
}
.sv_main.sv_main .progress {
  width: 100%;
  height: 20px;
}
.progress-bar {
  background-color: #7ea700;
  text-align: left;
  padding-left: 15px;
}
</style>